import React from 'react';

import Meta from '../components/meta';

import { StaticImage } from "gatsby-plugin-image";

import Cookies from 'js-cookie';

import ExtLink from '../components/extLink';

import Contents from '../components/contents';

import SubPageNav from '../components/subPageNav';

const Outline = () =>{

    //check GDPR
    // let useCookies = false;
    // useCookies = Cookies.get('cauth') && true;

    //set navigation cookie
    let selectNav;
    selectNav = Cookies.get('sn');

    if(!selectNav){
        selectNav = "outline";
    }

    // const dispCnt = (e) =>{
    //     e.preventDefault();

    //     let selector = e.target;

    //     let selectorBtn = selector.closest(".js-outline-nav").getElementsByClassName('p-outline-nav--btn');

    //     [].forEach.call((selectorBtn), elem =>{
    //         elem.classList.remove('is-active')
    //     });

    //     selector.classList.add('is-active');

    //     let selectorItem = selector.closest(".p-outline-wrap").getElementsByClassName('p-outline');
    //     let target = e.target.dataset.name;

    //     [].forEach.call((selectorItem), elem =>{
    //         elem.classList.remove('is-disp');

    //         if(target === elem.dataset.cnt){
    //             elem.classList.add('is-disp');
    //         }
    //     })

    //     if(useCookies === true){
    //         Cookies.set('sn',target);
    //     }
    // }



    return(
        <>

            <Meta
                title="会社概要"
                description="株式会社アクロスソリューションズの概要をご案内します。"
            />

            <Contents>

                <div className="c-sub-header">
                    <SubPageNav static="outline" />

                    <em className="c-sub-header--eng u-josef">
                    OUTLINE
                    </em>

                    <h1 className="c-sub-header--title">
                    会社概要
                    </h1>

                    <div className="p-outline-header">
                        <StaticImage
                            src="../images/image/p_outline_header.png"
                            alt="会社概要"
                            placeholder="blurred"
                            quality="100"
                            className="p-outline-header--image"
                        />
                    </div>
                </div>

                <div className="p-outline-wrap">

                    {/* <div className="p-outline-nav js-outline-nav">
                        <a href="#outline" title="会社概要" className={`p-outline-nav--btn ${selectNav === "outline" ? 'is-active':''} `} data-name="outline" onClick={dispCnt}>
                        会社概要
                        </a>

                        <a href="#history" title="沿革" className={`p-outline-nav--btn ${selectNav === "history" ? 'is-active':''} `} data-name="history" onClick={dispCnt}>
                        沿革
                        </a>
                    </div> */}

                    <div className={`p-outline ${selectNav === "outline" ? 'is-disp':''} `} data-cnt="outline">

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                会社名
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                株式会社アクロスソリューションズ
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                本社
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                〒920-0022<br />
                                石川県金沢市北安江3丁目6-6 メッセヤスダ1F/2F<br />
                                TEL 076-255-2012 / FAX 076-255-2013
                                </p>

                                <div className="c-link">
                                    <ExtLink link="https://goo.gl/maps/Dut7YMJeN9kjsvKg6" title="本社" classTag="c-link--btn is-external" target="true">
                                    地図を確認<span className="c-link--cursor"></span>
                                    </ExtLink>
                                </div>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                東京事務所
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                {/* {'＜'}DX事業本部{'＞'}<br /> */}
                                〒102-0083<br />
                                東京都千代田区麹町5-3-23 日テレ四谷ビル<br />

                                TEL 050-3085-2620 / FAX 076-255-2013　<br />
                                </p>

                                <div className="c-link">
                                    <ExtLink link="https://maps.app.goo.gl/kA6Zr5AVu5wCmnJ7A" title="東京事務所" classTag="c-link--btn is-external" target="true">
                                    地図を確認<span className="c-link--cursor"></span>
                                    </ExtLink>
                                </div>


                                
                                    {/* <p className="p-outline-box-cnt--text">
                                    {'＜'}デジタルマーケティング事業本部{'＞'}<br />
                                    〒101-0043<br />
                                    東京都千代田区神田富山町21　FKビル3F<br />
                                    TEL 03-5577-5153 / FAX 076-255-2013
                                    </p>

                                    <div className="c-link">
                                        <ExtLink link="https://goo.gl/maps/ThfvnfTt5L4oc6Ci6" title="東京支社 デジタルマーケティング事業本部" classTag="c-link--btn is-external" target="true">
                                        地図を確認<span className="c-link--cursor"></span>
                                        </ExtLink>
                                    </div> */}
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                大阪事務所
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                〒530-0017<br />
                                大阪府大阪市北区角田町8-1 大阪梅田ツインタワーズ・ノース 23F<br />
                                TEL 050-3033-5408 / FAX 076-255-2013
                                </p>

                                <div className="c-link">
                                    <ExtLink link="https://goo.gl/maps/CMLk62c2xFo" title="大阪事務所" classTag="c-link--btn is-external" target="true">
                                    地図を確認<span className="c-link--cursor"></span>
                                    </ExtLink>
                                </div>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                代表取締役社長
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                能登　満
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                取締役
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                野村 充史
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                監査役
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                前浜　隆
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                設立年月日
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                2006年5月23日
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                資本金
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                20,000,000円
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                メンバー
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                31名（契約社員含む、取締役除く、グループ総勢1,233名 2024年1月末現在・連結） 
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                業種
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                情報サービス業
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                事業内容
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                電子商取引システムの企画・設計・構築・販売・保守<br />
                                上記業務に係るマーケティングデータ解析及び分析等
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                グループ企業
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <ul className="p-outline-box-cnt-list">
                                    <li className="p-outline-box-cnt-list--item">
                                        <div className="c-link is-text">
                                            <ExtLink link="https://www.sts-inc.co.jp/" title="株式会社システムサポート" classTag="c-link--btn" target="true">
                                            株式会社システムサポート<span className="c-link--cursor"></span>
                                            </ExtLink>
                                        </div>
                                    </li>

                                    <li className="p-outline-box-cnt-list--item">
                                        <div className="c-link is-text">
                                            <ExtLink link="https://www.enetsolutions.co.jp/" title="株式会社イーネットソリューションズ" classTag="c-link--btn" target="true">
                                            株式会社イーネットソリューションズ<span className="c-link--cursor"></span>
                                            </ExtLink>
                                        </div>
                                    </li>

                                    <li className="p-outline-box-cnt-list--item">
                                        <div className="c-link is-text">
                                            <ExtLink link="https://www.sts-medic.jp/" title="株式会社STSメディック" classTag="c-link--btn" target="true">
                                            株式会社STSメディック<span className="c-link--cursor"></span>
                                            </ExtLink>
                                        </div>
                                    </li>

                                    <li className="p-outline-box-cnt-list--item">
                                        <div className="c-link is-text">
                                            <ExtLink link="https://www.t4c.co.jp/" title="株式会社T4C" classTag="c-link--btn" target="true">
                                            株式会社T4C<span className="c-link--cursor"></span>
                                            </ExtLink>
                                        </div>
                                    </li>

                                    <li className="p-outline-box-cnt-list--item">
                                        <div className="c-link is-text">
                                            <ExtLink link="https://sts-d.com/" title="株式会社STSデジタル" classTag="c-link--btn" target="true">
                                            株式会社STSデジタル<span className="c-link--cursor"></span>
                                            </ExtLink>
                                        </div>
                                    </li>

                                    <li className="p-outline-box-cnt-list--item">
                                        <div className="c-link is-text">
                                            <ExtLink link="https://www.cpnet.co.jp/" title="株式会社コミュニケーション・プランニング" classTag="c-link--btn" target="true">
                                            株式会社コミュニケーション・プランニング<span className="c-link--cursor"></span>
                                            </ExtLink>
                                        </div>
                                    </li>

                                    <li className="p-outline-box-cnt-list--item">
                                        <div className="c-link is-text">
                                            <ExtLink link="https://www.sts-innovation.com/" title="STS INNOVATION,INC." classTag="c-link--btn" target="true">
                                            STS INNOVATION,INC.<span className="c-link--cursor"></span>
                                            </ExtLink>
                                        </div>
                                    </li>

                                    <li className="p-outline-box-cnt-list--item">
                                        <p className="p-outline-box-cnt--text">
                                        STS Innovation Canada Inc.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                加盟団体
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                ISA（一般社団法人　石川県情報システム工業会）
                                </p>
                                <p className="p-outline-box-cnt--text">
                                JCSSA（一般社団法人日本コンピュータシステム販売店協会）
                                </p>
                                <p className="p-outline-box-cnt--text">
                                つなぐITコンソーシアム正会員
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                取引先
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <ul className="p-outline-box-cnt-list">
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社アドヴァンスト・インフォーメイション・デザイン
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社内田洋行
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社エクス
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    エコー電子工業株式会社
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    FFGSグラフィックサプライ株式会社
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社大塚商会
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社OSK
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社オーシーシー
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社GEEK
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社サイバーリンクス
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    CEC新潟情報サービス株式会社
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社シグマクレスト
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社シーライン
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    有限会社JOIN UP
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    有限会社信州開発
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社綜合システムプロダクツ
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社寺岡システム
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社電算システム
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社日本システムテクノロジー
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社浜田コンピューターシステム
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    ピー・シー・エー株式会社
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社日立ソリューションズ西日本
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社広島情報シンフォニー
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社富士ロジテックホールディングス
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    北陸コンピュータ・サービス株式会社
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    ヤマトクレジットファイナンス株式会社
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    リコージャパン株式会社
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    株式会社WorkVision
                                    </p>
                                    </li>
                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    和歌山ゼロックス株式会社
                                    </p>
                                    </li>

                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                        &nbsp;
                                    </p>
                                    </li>

                                    <li className="p-outline-box-cnt-list--item">
                                    <p className="p-outline-box-cnt--text">
                                    順不同
                                    </p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>



                    <div className={`p-outline ${selectNav === "history" ? 'is-disp':''} `} data-cnt="history">

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2006年<span className="is-date">5月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                株式会社アクロス設立
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2007年<span className="is-date">4月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                欧米向けクラフトショッピングモール開設をプロデュース
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2007年<span className="is-date">6月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                エクスポート・ジャパン株式会社と業務提携を締結
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2008年<span className="is-date">2月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                本社を金沢市千日町4-3に移転
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2009年<span className="is-date">9月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                ExseedJapan.comを石川県版に特化
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2009年<span className="is-date">12月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                国内初となる行動ターゲティングメール配信システム(アトム)をリリース
                                </p>
                                <p className="p-outline-box-cnt--text">
                                丸紅情報システムズ株式会社と業務協力を開始
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2010年<span className="is-date">1月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                国内初となる行動履歴マーケティングシステム(アトムス)をリリース
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2010年<span className="is-date">3月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                本社を金沢市西念1丁目2-26に移転
                                </p>
                                <p className="p-outline-box-cnt--text">
                                サイバーエリアリサーチ株式会社と業務提携を締結
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2010年<span className="is-date">8月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                行動履歴マーケティングシステム(アトムス)を特許出願
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2010年<span className="is-date">12月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                会社名を株式会社アクロスソリューションズに変更
                                </p>
                                <p className="p-outline-box-cnt--text">
                                資本金を5,000,000円に増資
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2011年<span className="is-date">2月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                丸紅情報システムズ各種ソリューションの取扱いを開始
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2011年<span className="is-date">4月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                モバイル動画サイト自動生成システム「ここみちゃん」をリリース
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2011年<span className="is-date">9月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                ウェブマーケティングのプロ国内100社に選定される
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2011年<span className="is-date">10月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                本社を金沢市北安江3丁目6番6号に移転
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2011年<span className="is-date">11月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                中小規模企業向けモバイル受発注システムMOSベータ版をリリース
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2012年<span className="is-date">3月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                株式会社システムサポートのグループ会社となり流通業向けソリューション強化を図る
                                </p>
                                <p className="p-outline-box-cnt--text">
                                資本金を10,000,000に増資
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2012年<span className="is-date">7月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                東京事務所を東京都新宿区西新宿6丁目12-1に開設
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2012年<span className="is-date">9月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                SMBCファイナンスサービスと業務提携を締結
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2013年<span className="is-date">1月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                中小規模企業向けモバイル受発注システムMOSをリリース
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2013年<span className="is-date">3月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                リテールテックJAPAN2013に出展(東京ビッグサイト)
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2013年<span className="is-date">9月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                大阪事務所を大阪府大阪市淀川区宮原3-5-24に開設
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2014年<span className="is-date">3月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                東京事務所を東京都渋谷区幡ヶ谷1-34-14宝ビル8Fに移転
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2015年<span className="is-date">7月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                小清水良次（株式会社システムサポート代表取締役社長）が代表取締役会長に就任
                                </p>
                                <p className="p-outline-box-cnt--text">
                                高井健司（株式会社システムサポート）が監査役に就任
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2016年<span className="is-date">3月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                SaaS型クラウド発注サービスMOXをリリース
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2016年<span className="is-date">10月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                中小規模企業向けモバイル受発注システムMOS4をリリース
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2017年<span className="is-date">4月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                    <ExtLink link="https://www.itc.or.jp/datarenkei/index.html" title="MOSが中小企業庁　平成28年度「次世代企業間データ連携調査事業」に採択される" target="true" classTag="c-text--link">
                                    MOSが中小企業庁　平成28年度「次世代企業間データ連携調査事業」に採択される
                                    </ExtLink>
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2018年<span className="is-date">1月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                小規模企業向けASP型受発注サービスMOS Liteをリリース
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2018年<span className="is-date">4月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                    <ExtLink link="https://www.smb-cloud.org/index.php/2020/04/01/moslite-2/" title="MOS Liteが「認定クラウドサービス（一般社団法人クラウドサービス推進機構）」に認定される" target="true" classTag="c-text--link">
                                    MOS Liteが「認定クラウドサービス（一般社団法人クラウドサービス推進機構）」に認定される
                                    </ExtLink>
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2019年<span className="is-date">2月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                中小規模企業向けモバイル受発注システムMOS5をリリース
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2019年<span className="is-date">7月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                宮﨑幹晴（株式会社システムサポート）が取締役に就任
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2019年<span className="is-date">8月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                システムサポートグループ連結としてマザーズに上場
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2020年<span className="is-date">8月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                システムサポートグループ連結として東証一部に上場
                                </p>
                            </div>
                        </div>

                    </div>

                </div>

            </Contents>

        </>
    )
}

export default Outline;


